<template>
  <div class="page--pimple-pop game bbb-pages">
    <div class="header">
      <div class="wrapper">
        <div class="progress-wrapper">
          <div class="progress">
            <div class="progress-bar" :style="{ width: `${game.state.persenProgress}%` }"></div>
          </div>
        </div>
        <a href="" class="close-button" @click.prevent="forceQuit()"
          ><img src="/static/img/pimple-pop/close-button@2x.png" width="86"
        /></a>
      </div>
      <span class="header__timer">00:{{ game.time }}</span>
    </div>

    <div class="game-arena">
      <div class="score">
        <span v-if="isVnSource" class="score__text">ĐIỂM</span>
        <span v-else class="score__text">SCORE</span>
        <span class="score__point">{{ game.zit }}</span>
      </div>

      <div class="game-arena__actor">
        <div class="game-arena__grid">
          <!-- <div class="col-pimple" :class="{[key]: true}" v-for="(item, key) in game.area">
						<a href="" class="actor"
							:class="{
								'unused-col': !item.isShow,
								[game.area[key].actor]: item.isShow}"
							@click.prevent="getActor($event,{index: key, actor: game.area[key].actor, show: item.isShow, point: item.point})"
						>
						</a>
						<transition name="pointfade">
							<span class="pimple-point" v-show="item.zitShow"></span>
						</transition>
					</div> -->

          <div v-for="(item, key) in game.area" :key="key" class="col-pimple" :class="{ [key]: true }">
            <a
              v-show="item.isShow"
              href=""
              class="actor"
              :class="[game.area[key].actor]"
              @contextmenu.prevent
              @click.prevent="
                getActor($event, { index: key, actor: game.area[key].actor, show: item.isShow, point: item.point })
              "
            >
              <img :src="image(game.area[key].actor)" />
            </a>
            <transition name="pointfade">
              <span v-show="item.zitShow" class="pimple-point"></span>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div v-show="game.state.isReadyToPlay === false" class="overlay">
      <span class="text">{{ game.countdown }}</span>
    </div>
    <audio
      v-if="game.countdown == 'GO!'"
      src="https://s3-ap-southeast-1.amazonaws.com/images.soco.id/big-bang-boom/Big-Bang-Boom_BGM.ogg"
      preload="auto"
      autoplay
    ></audio>
  </div>
</template>

<script>
import config from '@/shared/pimple-pop/configBigBangBoom';
import VendingMachineTracker from '@/mixins/vending-machine-tracker';

const Random = require('random-js');
const random = new Random(Random.engines.mt19937().autoSeed());
let timerState;

export default {
  name: 'PimplePopGame',
  mixins: [VendingMachineTracker],
  data() {
    return {
      game: {
        actor: {
          'actor--comet': {
            value: 3,
          },
          'actor--asteroid': {
            value: 2,
          },
          'actor--meteorite': {
            value: 1,
          },
          'actor--star': {
            value: -2,
          },
          'actor--mystery': {
            value: {
              plus: 10,
              minus: -15,
            },
          },
        },
        addPimple: this.addPimple,
        area: [],
        areaNumber: 9,
        code: {
          A: {
            name: 'actor--comet',
          },
          B: {
            name: 'actor--asteroid',
          },
          C: {
            name: 'actor--meteorite',
          },
          D: {
            name: 'actor--star',
          },
          E: {
            name: 'actor--mystery',
          },
        },
        countdown: 3,
        listNotAllowedAreaPimple: [],
        pimpleArea: [],
        randomArea: this.randomArea,
        readyPlay: this.readyPlay,
        removeAllPimple: this.removeAllPimple,
        removePimple: this.removePimple,
        resultsRandomArea: [],
        runTimer: this.runTimer,
        zit: 0,
        randomConfig: 0,
        start: this.start,
        state: {
          isReadyToPlay: false,
          persenProgress: 0,
          currentIndex: 0,
          patternIndex: null,
          mystery: null,
          arrIndexMystery: 0,
        },
        time: 15,
        playingTime: 15,
      },
    };
  },
  computed: {
    patterns() {
      return config[this.randomConfig];
    },
    isVnSource() {
      return this.$route.query.isvn;
    },
  },
  watch: {
    'game.state.isReadyToPlay'(newVal) {
      if (newVal) {
        this.start();
        this.runTimer();
      }
    },
  },
  mounted() {
    console.log('mounted');
    this.readyPlay();
  },
  created() {
    console.log('created');
    this.randomConfig = random.integer(0, 2);
    this.buildGameState();
  },
  methods: {
    image(item) {
      if (item == 'actor--comet') {
        return 'https://s3-ap-southeast-1.amazonaws.com/images.soco.id/big-bang-boom/item-comet.svg';
      }
      if (item == 'actor--asteroid') {
        return 'https://s3-ap-southeast-1.amazonaws.com/images.soco.id/big-bang-boom/item-asteroid.svg';
      }
      if (item == 'actor--meteorite') {
        return 'https://s3-ap-southeast-1.amazonaws.com/images.soco.id/big-bang-boom/item-meteoroid.svg';
      }
      if (item == 'actor--star') {
        return 'https://s3-ap-southeast-1.amazonaws.com/images.soco.id/big-bang-boom/item-star-v2.svg';
      }
    },
    addPimple() {
      const stateAddPimple = setInterval(() => {
        const randArea = this.game.randomArea();
        let actorPattern;

        if (this.game.time == '00') {
          clearInterval(stateAddPimple);
          this.game.removeAllPimple();
        }

        if (this.game.time != '00' && randArea) {
          for (let i = 0; i < randArea.length; i++) {
            actorPattern = this.patterns.start[this.game.state.currentIndex]
              ? this.patterns.start[this.game.state.currentIndex].actor[i]
              : false;
            this.game.area[randArea[i]].isShow = true;
            this.game.area[randArea[i]].clickCount = 0;
            this.game.area[randArea[i]].actor = actorPattern ? this.game.code[actorPattern].name : '';

            if (this.game.code[actorPattern] && this.game.code[actorPattern].name === 'actor--mystery') {
              this.game.area[randArea[i]].point = this.patterns.start[this.game.state.currentIndex].mystery_value;
            }

            if (this.game.pimpleArea.length > 17) {
              this.game.pimpleArea[this.game.pimpleArea.length - i] = randArea[i];
            } else {
              this.game.pimpleArea.push(randArea[i]);
            }
          }
        }

        this.game.state.currentIndex++;
      }, 1000);
    },
    buildGameState() {
      // set area
      for (let i = 0; i < this.game.areaNumber; i++) {
        if (this.$SO.inArray(i, this.game.listNotAllowedAreaPimple) > -1) {
          this.$set(this.game.area, i, { isShow: false, actor: null, isAllowed: false, zitShow: false, clickCount: 0 });
        } else {
          this.$set(this.game.area, i, { isShow: false, actor: null, isAllowed: true, zitShow: false, clickCount: 0 });
        }
      }
    },
    getActor(e, { index, actor, show, point }) {
      if (show && e.isTrusted) {
        this.game.area[index].clickCount = this.game.area[index].clickCount + 1;
        let zit = 0;
        if (actor === 'actor--mystery') {
          zit = this.game.actor['actor--mystery'].value[point];
        } else {
          zit = this.game.actor[actor].value;
        }

        if (actor === 'actor--red' && this.game.area[index].clickCount < 3) {
          // zit = 0
        }

        if (!isNaN(zit)) {
          this.game.zit += zit;
          // this.$store.dispatch('adjustZitPerGameJerawat', zit)
          // this.$store.dispatch('adjustZitJerawat', zit)

          if (
            (actor === 'actor--comet' && this.game.area[index].clickCount > 0) ||
            (actor === 'actor--asteroid' && this.game.area[index].clickCount > 0) ||
            (actor === 'actor--meteorite' && this.game.area[index].clickCount > 0) ||
            (actor === 'actor--mystery' && this.game.area[index].clickCount > 0) ||
            (actor === 'actor--star' && this.game.area[index].clickCount > 0)
          ) {
            this.game.area[index].isShow = false;
            this.game.area[index].actor = null;
            this.game.area[index].zitShow = true;
            if (zit !== 0) {
              e.target.parentElement.nextElementSibling.innerHTML = zit > 0 ? '+' + zit : zit;
              setTimeout(() => {
                this.game.area[index].zitShow = false;
              }, 10);
            }
          }
        }
      }
    },
    randomArea() {
      // use recursive concept
      // if doesnt meet empty area this function always call itselt until find a empty area
      let results;

      if (this.game.resultsRandomArea.length >= 3) {
        try {
          this.game.resultsRandomArea = [];
        } catch (err) {}
      }

      const randomNum = random.integer(0, 8);

      if (this.game.pimpleArea.length > 9) {
        if (this.game.area[randomNum].isAllowed) {
          if (this.$SO.size(this.game.resultsRandomArea) < 4) {
            this.game.area[randomNum].isShow = true;
            this.game.resultsRandomArea.push(randomNum);
            if (this.game.resultsRandomArea.length === 3) {
              results = Array.prototype.slice.call(this.game.resultsRandomArea);
              return results;
            } else {
              return this.randomArea();
            }
          }
        } else {
          return this.randomArea();
        }
      } else {
        if (this.game.area[randomNum].isShow || !this.game.area[randomNum].isAllowed) {
          try {
            return this.randomArea();
          } catch (err) {}
        } else {
          if (this.$SO.size(this.game.resultsRandomArea) < 4) {
            this.game.area[randomNum].isShow = true;
            this.game.resultsRandomArea.push(randomNum);
            if (this.game.resultsRandomArea.length === 3) {
              results = Array.prototype.slice.call(this.game.resultsRandomArea);
              return results;
            } else {
              return this.randomArea();
            }
          }
        }
      }
    },
    readyPlay() {
      this.$set(this.game, 'time', this.game.playingTime);
      const readyState = setInterval(() => {
        if (this.$SO.type(this.game.countdown) === 'number') {
          this.game.countdown--;
        }

        if (this.$SO.type(this.game.countdown) === 'string') {
          this.game.state.isReadyToPlay = true;
          clearInterval(readyState);
        }

        if (this.game.countdown === 0) {
          this.game.countdown = 'GO!';
        }
      }, 1000);
    },
    removePimple() {
      // FIFO flow
      const stateRemovePimple = setInterval(() => {
        let pimple;
        const pimpleArea = Array.prototype.slice.call(this.game.pimpleArea, 0, 3);
        for (let i = 0; i < pimpleArea.length; i++) {
          pimple = this.game.area[pimpleArea[i]];
          if (pimple.isShow) {
            pimple.isShow = false;
            pimple.actor = null;
            pimple.clickCount = 0;
            this.game.pimpleArea.shift(); // FIFO
          }
        }

        if (this.game.time == '00') {
          clearInterval(stateRemovePimple);
          this.game.removeAllPimple();
        }
      }, 2000);
    },
    removeAllPimple() {
      for (let i = 0; i < this.game.area.length; i++) {
        this.game.area[i].isShow = false;
        this.game.area[i].actor = null;
      }
    },
    runTimer() {
      timerState = setInterval(() => {
        this.game.state.persenProgress += 100 / this.game.playingTime;
        this.game.time--;
        if (this.$SO.type(this.game.time) === 'number' && this.game.time < 10) {
          this.game.time = '0' + this.game.time;
        }
        // the finish state of the game
        if (this.game.time == '00') {
          clearInterval(timerState);
          // this.$store.commit("setZits", 100)
          this.$store.commit('setZits', this.game.zit);
          if (this.isVnSource) {
            this.$router.push({ path: '/vending-machine-vn/pimple-pop/finish', query: { isvn: 'true' } });
          } else {
            this.$router.push('/vending-machine-id/pimple-pop/finish');
          }
        }
      }, 1000);
    },
    start() {
      const randArea = this.game.randomArea();
      for (let i = 0; i < randArea.length; i++) {
        this.game.area[randArea[i]].actor = this.game.code[this.patterns.ready.actor[i]].name;
        if (this.game.code[this.patterns.ready.actor[i]].name === 'actor--mystery') {
          this.game.area[randArea[i]].point = this.patterns.ready.mystery_value;
        }
        this.game.pimpleArea.push(randArea[i]);
      }

      this.game.addPimple();
      this.game.removePimple();
    },
    async forceQuit() {
      this.postTracker('play_close');
      clearInterval(timerState);
      this.$store.commit('setZits', 0);
      if (this.$route.query.isvn) {
        this.$router.push('/vending-machine-vn/home?isvn=true');
      } else {
        const path = (await Cookies.get('vending_machine_device')) == 'true' ? 'home' : 'login';
        this.$router.push('/vending-machine-id/' + path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bbb-pages {
  .header,
  .score {
    background: #000;
  }
  .progress-wrapper {
    background: transparent;
  }
  .progress-bar {
    background: #eb395f;
  }
  .header__timer,
  .score__point {
    color: #eb395f;
  }
  .game-arena {
    background: #000 url('/static/img/big-bang-boom/bg-bbb-4-store.png') no-repeat center -226px;
    background-size: 100% auto;
  }
  .game-arena__actor {
    background: none;
  }

  .actor {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: block;
    user-select: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: content;
      user-select: none;
    }
    &:not(.actor--star) img {
      filter: drop-shadow(-5px 10px 6px rgba(48, 0, 10, 0.7));
    }
  }

  .game-arena__grid {
    width: 980px;
    height: 980px;
    margin: 210px auto 0;
  }

  .actor--comet {
    opacity: 1;
    width: 49%;
    height: 75%;
  }
  .actor--asteroid {
    opacity: 1;
    width: 59%;
    height: 60%;
  }
  .actor--meteorite {
    opacity: 1;
    width: 50%;
    height: 49%;
  }
  .actor--star {
    opacity: 1;
    width: 65%;
    height: 65%;
  }

  .pimple-point {
    position: absolute;
    z-index: 1;
    top: 0;
    left: calc(50% - 30px);
    color: #fff;
    font-family: 'brandontext-bold', sans-serif;
    font-size: 72px;
    line-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }

  .pointfade-enter-active,
  .pointfade-leave-active {
    transition: all 1s;
  }

  .pointfade-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }

  .col-pimple {
    width: calc(100% / 3) !important;
    height: calc(100% / 3) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    user-select: none;
  }
}
</style>
